@import '../../node_modules/tailwindcss/base.css';
@import '../../node_modules/tailwindcss/components.css';
@import '../../node_modules/tailwindcss/utilities.css';
@import 'brand_theme.css';
@import 'reset.css';

.bg-gray-50 {
  background-color: var(--Gray-50);
}

.bg-purple-700 {
  background-color: var(--Brand-700);
}

.text-purple-700 {
  color: var(--Brand-700);
}

.w-full {
  width: 100%;
}

.navbar-mobile {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
}

.navbar-mobile_menu-hamburger {
    width: 30px;
    cursor: pointer;
}

.navbar-mobile_company-logo {
    width: 100px;
    cursor: pointer;
    box-shadow: 0 3px 4px 0 rgba(16, 24, 40, 0.05);
}

.navbar-mobile_login-button {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--Brand-50, #F9F5FF);
    background: var(--Brand-50, #F9F5FF);
    font-weight: bold;
    cursor: pointer;
    box-shadow: 2px 3px 4px 0 rgba(16, 24, 40, 0.05);
}

.navbar-mobile_nav-items {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
}

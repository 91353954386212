.mobile_search {
    width: 100%;
    margin-top: 16px;
}

.mobile_search-internal_div {
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
    display: grid;
    gap: 16px;
}
.mobile_search-main_text {
    color: #FFF;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    line-height: 28px;
}
.mobile_search-main-text_div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobile_search-search_button {
    transform: translateY(50px);
    border-radius: 50px;
    border: 1px solid var(--Brand-50);
    background-color: var(--Brand-50);
    margin: 0 auto;
    box-shadow: 0 3px 2px 2px rgba(16, 24, 40, 0.05);
    display: flex;
    width: 200px;
    height: 45px;
    padding: 0 16px;
    align-items: center;
    justify-content: space-around;
    color: var(--Brand-700);
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    transition: background-color 0.3s ease;
}
.mobile_search-search_button:hover {
    box-shadow: 0 4px 6px 0 rgba(16, 24, 40, 0.05);
    background-color: var(--Gray-200);
    cursor: pointer;
}
:root {
    --primary-color-700: var(--Brand-700);
    --font-familly-poppins: Poppins;
    --font-familly-inter: Inter;
    --font-familly-primary: var(--font-familly-poppins);
    --font-familly-secondary: var(--font-familly-inter);
    --font-size: 16px;
    --padding: 10px;
    --White: #FFF;
    --Black: #000;

    --Gray-50: #F9FAFB;
    --Gray-200: #EAECF0;
    --Gray-300: #D0D5DD;
    --Gray-400: #98A2B3;
    --Gray-500: #667085;
    --Gray-700: #344054;
    --Gray-900: #101828;

    --Brand-50: #F9F5FF;
    --Brand-200: #b99dfa;
    --Brand-600: #7F56D9;
    --Brand-700: #6941C6;
    --Brand-900: #42307D;
    --Success-600: #039855;
}
.font-inter {
    font-family: var(--font-familly-inter);
}
.font-poppins {
    font-family: var(--font-familly-poppins);
}

/*:root {*/
/*    --Brand-0: #f6f2ff;*/
/*    --Brand-100: #d8c8fd;*/
/*    --Brand-200: #b99dfa;*/
/*    --Brand-300: #9d77f6;*/
/*    --Brand-400: #8456ef;*/
/*    --Brand-500: #6f3ce7;*/
/*    --Brand-600: #5e28dc;*/
/*    --Brand-700: #4f18cf;*/
/*    --Brand-800: #420dbe;*/
/*    --Brand-900: #3806ab;*/
/*    --Brand-1000: #2e0295;*/
/*    --Brand-1100: #260080;*/
/*    --color-blue-0: #f5f8ff;*/
/*    --color-blue-100: #cedefd;*/
/*    --color-blue-200: #a8c3fa;*/
/*    --color-blue-300: #84aaf6;*/
/*    --color-blue-400: #6694ef;*/
/*    --color-blue-500: #4d81e7;*/
/*    --color-blue-600: #3a70dc;*/
/*    --color-blue-700: #2b61cf;*/
/*    --color-blue-800: #1f54be;*/
/*    --color-blue-900: #1748ab;*/
/*    --color-blue-1000: #113d95;*/
/*    --color-blue-1100: #0d3380;*/
/*}*/

.bg-gray-50 {
    background-color: var(--Gray-50);
}

.bg-purple-700 {
    background-color: var(--Brand-700);
}

.text-purple-700 {
    color: var(--Brand-700);
}

.w-full {
    width: 100%;
}

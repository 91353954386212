.purple_tag-main_div {
    display: flex;
    padding: 0 16px;
    align-items: flex-start;
    border-radius: 16px;
    background-color: var(--Brand-50, #F9F5FF);
    flex: none;
    width: fit-content;
}
.purple_tag-label {
    display: flex;
    gap: 4px;
    color: var(--Brand-700);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 2px 4px;
}
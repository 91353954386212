.mobile_search-input-section {
    width: 100%;
    box-sizing: border-box;
}

.mobile_search-input {
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--White, #FFF);

    /* Shadow/xs */
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.20);
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    width: 100%;

    color: var(--Gray-500, var(--Gray-500, #667085));
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
}

.mobile_search-label {
    color: var(--White, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.recent_search_tag-main_div {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 8px 16px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--Gray-200, #EAECF0);
    background: var(--White, #FFF);
    cursor: pointer;
}

.recent_search_tag-main_div:hover {
    background: var(--Gray-50, #F9FAFB);
}

.recent_search_tag-label {
    display: flex;
    justify-content: flex-end;
    gap: 4px;
    color: var(--Gray-700, #344054);
    font-family: var(--font-familly-poppins);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}